import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import { Image } from 'antd';

import { api } from '../../api';

export default class Showcategorypic extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
          news:[],
          piccategorylimit:[],
          visible:false,
          protect:'',
        };
       this.getcategory = this.getcategory.bind(this);
       this.delete = this.delete.bind(this);
       this.showpicc = this.showpicc.bind(this);
       this.showpicclose = this.showpicclose.bind(this);
       
      }

      componentDidMount() {
      this.getcategory();
       
      }

      getcategory() {
        setTimeout(() => {
           //ภายใน
           api.get('selectcategorypic.php?id_category='+this.props.id_category )
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ piccategorylimit: res.data})
             if (res.data.length >0) {
                this.setState({ protect: res.data[0].file_picture})
             }
             
           }) 
         }, 0);
      }

      delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletenews.php?id=' + id)
            .then((res) => {
                this.getnews()
            })
      }

      showpicc() {
        this.setState({ visible: true })
    }

    showpicclose = (id) => {
        this.setState({ visible: id })
    }



  render() {
    console.log(this.state.news)

 

    return (
     
      <MDBContainer>
       
        <div className="d-flex justify-content-center">
        <div className="p-2 col-example text-center">
        <Image
        preview={{
          visible: this.state.visible
        }}
        width="250px"
        height="120px"
        src={`https://www.bankchiangmaiservice.com/cradlebankbackend/uploadspic/${this.state.protect}`}
        onClick={this.showpicc}
        className="img-fluid z-depth-1"
      />
      {/* <p>sds</p> */}
      <div
        style={{
          display: 'none',
          
        }}
      >
        <Image.PreviewGroup
          preview={{
            visible: this.state.visible,
            onVisibleChange: (vis) => this.showpicclose(vis),
          }}
         
        >
                                    {this.state.piccategorylimit.map((piccategorylimit, index) => {
                                        return <>
                                         <Image src={`https://www.bankchiangmaiservice.com/cradlebankbackend/uploadspic/${piccategorylimit.file_picture}`}  width="250px" height="120px"/>
                                        {/* {categorylimit.name_category }<br/>{categorylimit.date_category }              */}
                                        </>
                                    })}
         
        </Image.PreviewGroup>
        </div>

                                  
    
      {/* <div className="p-2 col-example text-left">Flex item 2</div>
      <div className="p-2 col-example text-left">Flex item 3</div> */}

    </div>
    </div>  
      </MDBContainer>
    )
  }
}
