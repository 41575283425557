import React, { Component } from 'react'
import {
    MDBContainer,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';
import { MDBJumbotron, MDBAlert, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip, Divider } from 'antd';
import { api } from '../../api';
import "../../index.css";
import Swal from 'sweetalert2'
import { Image } from 'antd';
import LoadingOverlay from 'react-loading-overlay';

//import Lightroom from 'react-lightbox-gallery'
//import Lightbox from "react-awesome-lightbox";
//import "react-awesome-lightbox/build/style.css";
// You need to import the CSS only once
//import "react-awesome-lightbox/build/style.css";
//import Lightbox from 'react-lightbox-component';
//import Lightbox from 'react-image-lightbox';
//import "photoswipe/dist/photoswipe.css";
//import "photoswipe/dist/default-skin/default-skin.css";

//import { Gallery, Item } from "react-photoswipe-gallery";
//import { SRLWrapper } from "simple-react-lightbox";


import Lightbox from "react-lightbox-component";
import "react-lightbox-component/build/css/index.css";

export default class Modaladdpictrue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modal: false,
            loading: false,
            namework: '',
            fileupload: [],
            pic: [],
            namecategory: '',

            name_workorder: '',
            worklistdata: [],

            filedesing_workorder: '',

            photoIndex: 0,
            isOpen: false,

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChangefile = this.handleChangefile.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.getpic = this.getpic.bind(this);
        this.showpicc = this.showpicc.bind(this);
        this.showpicclose = this.showpicclose.bind(this);
        this.delete = this.delete.bind(this);
    }
    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    //handleChangefile(event) {    this.setState({fileupload: event.target.files[0]});  }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.getpic();
    }

    handleChangefile(event) {
        // console.log(event.target.files)
        this.setState({ fileupload: event.target.files });
    }

    showpicc() {
        this.setState({ visible: true })
    }

    showpicclose = (id) => {
        this.setState({ visible: id })
    }

    delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletepic.php?id=' + id)
            .then((res) => {
                this.getpic()
            })
      }

    getpic() {
        this.setState({ loading: true })
        setTimeout(() => {
            //ภายใน
            api.get('selectpic.php?id_category=' + this.props.id_category)
                .then(res => {
                    console.log(res);
                    //  this.setState({ bankname: res.data, loading: false })
                    this.setState({ pic: res.data, loading: false })
                })
        }, 0);
    }

    handleSubmit(e) {
        e.preventDefault();
        // alert('A name was submitted: ' + this.state.value);
        const date = new Date().toISOString().slice(0, 10)
        const id = this.props.id_category;

        for (let index = 0; index < this.state.fileupload.length; index++) {
            //  console.log(index);
            //   console.log(id);

            setTimeout(() => {
                const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
                datafrm.append("id_piccategory", id);
                datafrm.append("file_picture", this.state.fileupload[index]);
                datafrm.append("date_picture", date);
                datafrm.append("status_picture", 1);

                api.post("updatefilepictrue.php", datafrm, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })

                    .then(res => {
                        // if (res.status=== 200) {
                        //     Swal.fire({
                        //         title: 'บันทึกข้อมูลสำเร็จ',
                        //         icon: 'success',

                        //         confirmButtonText: 'ตกลง',
                        //     }).then((result) => {
                        //         this.toggle();  
                        //         this.props.getcategory();
                        //     })
                        // }else{
                        // message.error("บันทึกข้อมูลไม่สำเร็จ!")
                        // }
                    })
            }, 1000);
            if (this.state.fileupload.length === index + 1) {
                Swal.fire({
                    title: 'บันทึกข้อมูลสำเร็จ',
                    icon: 'success',

                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    this.getpic();
                   // this.toggle();
                    //  this.props.getcategory();
                })
            }
        }



    }

    render() {
        // console.log(this.state.fileupload[0])

        console.log(this.state.pic)

        // const images = [
        //     {
        //         url:"https://i.pinimg.com/736x/fd/80/f6/fd80f6aae71ec15d6e8955a10b61dd65.jpg",
        //         title:"image title 1"
        //     },
        //     {
        //         url:"https://thumbs.dreamstime.com/b/white-flower-beautiful-hd-pic-japantown-215486907.jpg",
        //         title:"image title 2"
        //     }
        // ];

        // const images =  [
        //       {
        //         src: 'https://i.pinimg.com/736x/fd/80/f6/fd80f6aae71ec15d6e8955a10b61dd65.jpg',
        //         title: 'image title',
        //         description: 'image description'
        //       },
        //       {
        //         src: 'https://thumbs.dreamstime.com/b/white-flower-beautiful-hd-pic-japantown-215486907.jpg',
        //         title: 'image title',
        //         description: 'image description'
        //       }
        //     ]

        // const photos = [
        //     {
        //         src: "https://i.pinimg.com/736x/fd/80/f6/fd80f6aae71ec15d6e8955a10b61dd65.jpg",
        //         width: 800,
        //         height: 600
        //     },
        //     {
        //         src: "https://thumbs.dreamstime.com/b/white-flower-beautiful-hd-pic-japantown-215486907.jpg",
        //         width: 1600,
        //         height: 900
        //     }
        // ];

        var images = [
            {
                src:
                    "https://upload.wikimedia.org/wikipedia/commons/8/89/Ropy_pahoehoe.jpg",
                title: "Ropy pahoehoe",
                description:
                    "By Tari Noelani Mattox. Licensed under Public Domain via Commons"
            },
            {
                src:
                    "https://upload.wikimedia.org/wikipedia/commons/7/73/Pyroclastic_flows_at_Mayon_Volcano.jpg",
                title: "Pyroclastic flows at Mayon Volcano",
                description: "By C.G. Newhall. Licensed under Public Domain via Commons"
            },
            {
                src: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Okataina.jpg",
                title: "Okataina",
                description: "By Richard Waitt. Licensed under Public Domain via Commons"
            }
        ];


        return (

            <MDBContainer>
                <MDBBtn color="purple" size="sm" onClick={this.toggle}>เพิ่มภาพ</MDBBtn>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <MDBModalHeader toggle={this.toggle}>เพิ่มภาพผลงาน</MDBModalHeader>
                    <LoadingOverlay
                        active={this.state.loading}
                        spinner
                        text='Loading your data...'
                    >
                        <MDBModalBody>
                            <h3>ภาพผลงาน{this.props.name_category}</h3>
                            <form onSubmit={this.handleSubmit}>
                                {/* <div className="mb-2">
                                <label className="font-weight-bold"><MDBIcon icon="bible" /> ชื่ออัลบั้มภาพกิจกรรม</label>
                                <input className="form-control" onChange={this.onTextChange} name="namecategory"
                                    value={this.state.namecategory} placeholder="" required />
                            </div> */}
                                <div className="mb-2">
                                    <label className="font-weight-bold"><MDBIcon icon="bible" /> เพิ่มภาพผลงาน</label><br />
                                    {/* <input id='fileUpload'  type='file' multiple name="fileupload" onChange={this.handleChangefile} accept='application/pdf, image/png'/> */}
                                    <input type="file" id="img" multiple name="fileupload" onChange={this.handleChangefile} accept="image/x-png,image/jpg,image/jpeg" ></input>
                                </div>




                                {/* <Lightbox images={images}></Lightbox> */}
                                {/* <Lightbox images={images}  showImageModifiers={false} /> */}
                                {/* <Lightbox images={images} title="Image Title"></Lightbox> */}

                                {/* <Lightbox images={
                                [
                                    {
                                    src: 'https://thumbs.dreamstime.com/b/white-flower-beautiful-hd-pic-japantown-215486907.jpg',
                                    title: 'image title',
                                    description: 'image description'
                                    
                                    }
                                ]
                                }/> */}
                                {/* <Lightbox images={images} /> */}


                                {/* <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p> */}
                                <div className="mt-3 text-center" >
                                    <MDBBtn type="submit" color="deep-purple" className="text-center"> <MDBIcon far icon="save" /> บันทึก</MDBBtn>
                                    <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                                </div>

                            </form>
                            <br />

                            {/* <Image
        preview={{
          visible: this.state.visible
        }}
        width={200}
        src="https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp"
        onClick={this.showpicc}
      />
      <div
        style={{
          display: 'none',
        }}
      >
        <Image.PreviewGroup
          preview={{
            visible: this.state.visible,
            onVisibleChange: (vis) => this.showpicclose(vis),
          }}
        >
          <Image src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp" />
          <Image src="https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp" />
          <Image src="https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp" />
        </Image.PreviewGroup>
        </div> */}



                            <MDBTable bordered>
                                <MDBTableHead>
                                    <tr>
                                        <th>ลำดับ</th>
                                        <th>ภาพ</th>
                                        <th>วันที่</th>
                                        <th>จัดการ</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.pic.map((pic, index) => {
                                        return <tr>
                                            <td>{index + 1}</td>
                                            <td><Image
                                                width={200}
                                                src={`https://www.bankchiangmaiservice.com/cradlebankbackend/uploadspic/${pic.file_picture}`}
                                            />
                                                {/* <img src={`http://localhost/medicinebackend/uploadspic/${pic.file_picture}`} className="img-fluid" alt="" width="20%" /> */}
                                            </td>
                                            <td>{pic.date_picture}</td>
                                            <td> <MDBBtn size="sm" color="unique" onClick={() => this.delete(pic.id_picture)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn></td>
                                        </tr>
                                    })}
                                </MDBTableBody>
                            </MDBTable>




                        </MDBModalBody>
                    </LoadingOverlay>
                    <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                        {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }
}
