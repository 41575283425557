import React, { Component } from 'react'
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import "../index.css";
export default class Footter extends Component {
    render() {
        return (
            <div className='mt-5'>
        <footer className="footer--pin ">
        <MDBFooter color="danger-color-dark" className="font-small ">
          <MDBContainer fluid className="text-center text-md-right">
            <MDBRow>
              <MDBCol md="12">
                <br/>
                <h5 className="title">อู่แบ้งค์รถยก</h5>
                <p>
                0650961963
                </p>
              
              </MDBCol>
            
            </MDBRow>
          </MDBContainer>
          <div className="footer-copyright text-center py-3">
            <MDBContainer fluid>
              &copy; {new Date().getFullYear()} Copyright: <a href=""> อู่แบ้งค์รถยก </a>
            </MDBContainer>
          </div>
        </MDBFooter>
        </footer>
            </div>
        )
    }
}
